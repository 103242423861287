<template>

    <div class="container-show-detail" :style="`background-image: url(${returnImage()})`" v-if="hasImage()"></div>

</template>

<script>
import util from "@/mixins/util";

export default {
  name: "BoardDetailDefaultLayout",
  mixins: [],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {
    boardData: {default:() => []},
  },
  data() {
    return {}
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    hasImage() {
      if ( this.boardData.hasOwnProperty( 'File' ) && !util.isEmpty(this.boardData.File[0]) ) {
        return true;
      }
    },

    returnImage() {
      if ( this.boardData.File.length > 0 && !util.isEmpty(this.boardData.File[0]) ) {
        return this.boardData.File[0].org_url
      }
    },
  },
  watch: {},
}
</script>

<style scoped>

</style>
